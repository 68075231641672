.PageFooterLinks.PageFooterLinks {
  background-color: var(--page-footer-background-top);
}

.PageFooterLinks .PageFooterLinks-heading {
  color: var(--page-footer-text);
}

.PageFooterLinks.PageFooterLinks .PageFooterLinks-link {
  color: var(--page-footer-text-link);
}

.PageFooter-mid.PageFooter-mid {
  background-color: var(--page-footer-background-mid);
}

.PageFooter-mid.PageFooter-mid .PageFooter-link {
  color: var(--page-footer-text-link);
}

.PageFooter-mid.PageFooter-mid .PageFooter-link-alt {
  color: var(--page-footer-text-link-alt);
}

.PageFooter-bottom.PageFooter-bottom {
  background-color: var(--page-footer-background-bottom);
}

.PageFooter-bottom.PageFooter-bottom .PageFooter-link {
  color: var(--page-footer-text-link);
}

.PageFooter-bottom.PageFooter-bottom .PageFooter-link-alt {
  color: var(--page-footer-text-link-alt);
}

.PageFooter-heading,
.PageFooter-text {
  color: var(--page-footer-text);
}

.PageFooter-copy.PageFooter-copy {
  display: grid;
  grid-area: 1/15/1/25;
}

.PageFooter-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 955px) {
  .PageFooter-bottom {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media (min-width: 768px) {
  .PageFooter-container {
    flex-direction: row;
    justify-content: space-between;
  }
}
