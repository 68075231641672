.PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper,
.PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper--with-rating {
  background-color: var(--colour-primary-600);
  box-shadow: var(--cta-primary-hover-box-shadow-dark);
}

.PageHeader-wrapper--with-rating .HLP-hero-rating-star use {
  fill: var(--colour-secondary);
}

.PageHeader-wrapper--with-rating .HLP-hero-rating-star--cut use {
  fill: var(--colour-primary-600);
}

.PageHeader-CTA-block.PageHeader-CTA-block.PageHeader-CTA-block {
  background-color: var(--colour-secondary);
  border-color: var(--colour-secondary);
  color: var(--colour-primary-600);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .PageHeader-CTA-block.PageHeader-CTA-block.PageHeader-CTA-block:hover {
    transform: translateY(-1px);
  }
}

@media (max-width: 767px) {
  .PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper--compact {
    background-color: var(--colour-background-025);
    color: var(--colour-primary-600);
    display: flex;
    padding: calc(var(--base-unit) * 1);
  }

  .PageHeader-wrapper--checkout.PageHeader-wrapper--checkout.PageHeader-wrapper--checkout {
    background-color: var(--colour-primary-600);
    padding: 0 calc(var(--base-unit) * 2);
  }
}

@media (min-width: 767px) {
  .PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper--compact {
    padding: var(--base-unit) 0;
  }
}

@media (max-width: 339px) {
  .PageHeader-logo-link.PageHeader-logo-link--compact {
    overflow: hidden;
    width: 30px;
  }
}

.PageHeader-CTA-block.PageHeader-CTA-block.PageHeader-CTA-block:focus-visible {
  outline: 2px solid var(--colour-white);
}
