.UA-logged-out-banner {
  background: url({{ CDN_S }}images/at_f/UserAccount/MHDE-bg.png) no-repeat 50%
    50% / cover;
}

.UA-logged-out-banner-wrap > * {
  color: var(--colour-primary-600);
}

@media (min-width: 1200px) {
  .UA-menu {
    background-color: var(--colour-background-015);
  }

  .UA-menu-item,
  .UA-menu span {
    color: var(--colour-primary-400);
  }
}
