@font-face {
  font-family: 'Noto Sans';
  src: url('https://cdn.bungalow.de/s/webfonts/noto-sans/NotoSans-Regular.woff2')
      format('woff2'),
    url('https://cdn.bungalow.de/s/webfonts/noto-sans/NotoSans-Regular.woff')
      format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('https://cdn.bungalow.de/s/webfonts/noto-sans/NotoSans-Bold.woff2')
      format('woff2'),
    url('https://cdn.bungalow.de/s/webfonts/noto-sans/NotoSans-Bold.woff')
      format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Merriweather';
  src: url('https://cdn.bungalow.de/s/webfonts/merriweather/merriweather-bold.woff2')
      format('woff2'),
    url('https://cdn.bungalow.de/s/webfonts/merriweather/merriweather-bold.woff')
      format('woff');
  font-style: normal;
  font-weight: 400;
}
