:root {
  /* semantic variables */
  --colour-primary-800: #254138;
  --colour-primary-600: #324c43;
  --colour-primary-400: #526861;

  --colour-secondary: #e1b254;

  --colour-tertiary-200: #019759;
  --colour-tertiary-400: #007243;

  --colour-award: #f8bd32;

  --colour-warning: #ffa366;

  --colour-error: #ea4c3b;

  --colour-paper-015: #fdf9f2;
  --colour-paper-025: #f9f0dd;

  --colour-success-025: #e5f4ee;
  --colour-success-050: #afdfcb;
  --colour-success-100: #79c8a8;
  --colour-success-400: #007243;

  --colour-neutral-025: #f1f1f1;
  --colour-neutral-050: #d4d4d4;
  --colour-neutral-100: #b9b9b9;
  --colour-neutral-200: #848484;
  --colour-neutral-400: #636363;
  --colour-neutral-600: #474747;

  --colour-background-010: #fcfcfb;
  --colour-background-015: #faf9f6;
  --colour-background-025: #faf6ed;
  --colour-background-050: #d7d4cc;

  --accent-green-200: #1e9575;

  --colour-white: #fff;
  --colour-black: #000;

  /* contextual variables */
  --page-background-light: var(--colour-background-010);
  --page-background-dark: var(--colour-background-015);

  --text-main-on-dark: var(--colour-white);
  --text-main-on-light: var(--colour-primary-600);
  --text-on-secondary: var(--colour-primary-600);
  --text-accent-on-dark: var(--colour-secondary);
  --text-accent-on-light: var(--colour-secondary);
  --border-radius-cta: 2px;
  --border-radius-inputs: 2px;
  --border-radius-card-medium: 2px;
  --border-radius-card-large: 4px;

  /* fonts: START */
  --base-font-size: 16px;
  --base-font-family: 'Noto Sans', Lato, 'Open Sans', Helvetica, Montserrat;

  --fancy-font-family: 'Merriweather', Georgia, 'Times New Roman', Palatino,
    Baskerville, Garamond;
  /* fonts: END */

  /* elements/CTA: START */
  --cta-primary-hover-box-shadow: 0 6px 12px -8px var(--colour-black),
    0 5px 30px -5px rgba(225, 178, 84, 0.6);
  --cta-primary-hover-box-shadow-dark: 0 4px 16px rgba(0, 0, 0, 0.05);
  --cta-primary-box-shadow: 0 6px 12px -8px var(--colour-black),
    0 5px 30px -5px var(--colour-secondary);
  --cta-primary-box-shadow-with-opacity: 0 6px 18px -15px var(--colour-primary-600),
    0 5px 30px -5px rgba(225, 178, 84, 0.6);
  /* elements/CTA: END */

  --border-radius-medium: 1px;
  --border-radius-small: 1px;

  --quiz-step-transition-background: rgba(250, 246, 237, 0.9);
  /*background025 colour with 0.9 opacity*/

  /* templates/PageFooter: START */
  --page-footer-background-top: var(--colour-primary-400);
  --page-footer-background-mid: var(--colour-primary-600);
  --page-footer-background-bottom: var(--colour-primary-800);

  --page-footer-text: var(--colour-white);
  --page-footer-text-link: var(--colour-secondary);
  --page-footer-text-link-alt: var(--colour-white);
  /* templates/PageFooter: END */

  --hero-background-overlay: linear-gradient(
    rgba(50, 76, 67, 0.85),
    rgba(50, 76, 67, 0.85)
  );
  --hero-background-overlay-mobile: linear-gradient(
    rgba(50, 76, 67, 0.85) 40%,
    rgba(50, 76, 67, 0.85) 55%
  );
  --hero-background-overlay-bar-tiles: linear-gradient(
    rgba(50, 76, 67, 0.4),
    rgba(50, 76, 67, 0.4)
  );

  --search-result-background: var(--colour-background-010);
  --radio-checked-color: var(--colour-secondary);

  --guidebook-activation-modal-shadow: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(225, 178, 84, 0.6)
  );
}
